import { useState } from "react";
import axiosInstance from '../../axiosInstance';


const AuthPasswordForgot = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        try {
            const response = await axiosInstance.post(`/auth/password-recovery`,  { email: email });

            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.detail || "An error occurred.");
        }
    };

    return (
        <div className="auth-container">
            <h2>Forgot Password</h2>
            <form className="auth-form" onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <div className="action-buttons">
                    <button className="btn" type="submit">Send Reset Link</button>
                </div>
            </form>
            {message && <p className={message.includes("error") ? "error-text" : "success-message"}>{message}</p>}
        </div>
    );
};

export default AuthPasswordForgot;
