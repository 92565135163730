import { useState, useEffect } from "react";
import axiosInstance from '../axiosInstance';
import React from 'react';
import PantryList from "./Pantry/List";
import GroceryListLatest from "./Grocery/ListLatest";
import RecipeListSelected from "./Recipe/ListSelected";
import RecipeWithAllIngredients from "./Recipe/WithAllIngredients";
import WeeklyMealPlannerDisplay from './WeeklyMealPlanner/Display'


const UserPage = ({ userId, username }) => {
    const [usage, setUsage] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/ai-usage/${userId}`)
            .then((response) => setUsage(response.data))
            .catch((error) => console.error("Error fetching AI usage:", error));
    }, [userId]);

    return (
        <div className="page-base">
            <h1>{userId}'s Page</h1>
            <div className="user-page-content">
                <div className="pantry-items">
                    <PantryList userId={userId}/>
                </div>
                <div className="selected-recipes">
                    <RecipeListSelected userId={userId}/>
                </div>
                <div className="selected-recipes">
                    <RecipeWithAllIngredients userId={userId}/>
                </div>
                <div className="recent-grocery-list">
                    <GroceryListLatest userId={userId}/>
                </div>
            </div>
            {/*<div className="page-content">*/}
            {/*    <div className="user-meal-planner">*/}
            {/*        <WeeklyMealPlannerDisplay userId={userId}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div>
                <p><strong>AI Usage Limits:</strong> Receipts -> {usage?.receipt_uploads} / {usage?.limits?.receipt_uploads},
                Recipes Fetched -> {usage?.recipe_fetch} / {usage?.limits?.recipe_fetch},
                Recipe Suggestions -> {usage?.recipe_suggestions} / {usage?.limits?.recipe_suggestions}
                </p>
            </div>
        </div>
    );
};

export default UserPage;
