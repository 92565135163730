import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from '../../axiosInstance';
import "./Edit.css";


const GroceryEdit = ({ userId }) => {
    const [groceryList, setGroceryList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [newItem, setNewItem] = useState({ name: "", quantity: 1 });
    const [importing, setImporting] = useState(false);

    const handleImportIngredients = async () => {
        setImporting(true);
        try {
            const response = await axiosInstance.post(`/grocery-list/${userId}/import-selected-recipes`);
            alert(response.data.message);
        } catch (error) {
            console.error("Error importing ingredients:", error);
            alert("Failed to import ingredients.");
        }
        setImporting(false);
    };

    const handleInputChange = (index, field, value) => {
        const updatedItems = [...groceryList.items];
        updatedItems[index][field] = value;
        setGroceryList({ ...groceryList, items: updatedItems });
    };

    const handleAddRow = () => {
        setGroceryList({
            ...groceryList,
            items: [...groceryList.items, { name: newItem.name, quantity: newItem.quantity }],
        });
        setNewItem({ name: "", quantity: 1 });
    };

    const handleDeleteRow = (index) => {
        setGroceryList({
            ...groceryList,
            items: groceryList.items.filter((_, i) => i !== index),
        });
    };

    const handleDeleteList = async () => {
        if (window.confirm("Are you sure you want to delete the entire grocery list?")) {
            try {
                await axiosInstance.delete(`/grocery-list/${groceryList.id}/delete-list`);
                setGroceryList(null);
                alert("Grocery list deleted successfully.");
            } catch (err) {
                console.error("Error deleting grocery list:", err);
                setError("Failed to delete grocery list.");
            }
        }
    };

    const handleSaveChanges = async () => {
        if (!groceryList?.id) {
            alert("No grocery list available to save.");
            return;
        }

        try {
            const response = await axiosInstance.post(`/grocery-list/${userId}/save`, groceryList);
            setGroceryList(response.data);
            alert("Grocery list saved successfully!");
        } catch (err) {
            console.error("Error saving grocery list:", err);
            setError("Failed to save grocery list.");
        }
    };

    const fetchGroceryList = useCallback(async () => {
        try {
            const response = await axiosInstance.get(`/grocery-list/${userId}/latest`);
            setGroceryList(response.data);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching grocery list:", err);
            setError("Failed to load grocery list.");
            setLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        fetchGroceryList();
    }, [userId, fetchGroceryList]);


    // TODO if grocery list is deleted need a button for pinging the latest (latest autocreates)
    if (loading) return <p>Loading grocery list...</p>;
    if (error) return <p className="error">{error}</p>;
    if (!groceryList) {
        return (
            <div className="no-grocery-list">
                <p>No grocery list available.</p>
                <button className="btn btn-primary" onClick={fetchGroceryList}>
                    Create New Grocery List
                </button>
            </div>
        );
    }

    return (
        <div className="page-base">
            <h1>Grocery List ({groceryList?.date.split("T")[0] || "Unknown Date"})</h1>

            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Estimated Cost</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {groceryList.items && groceryList.items.length > 0 &&
                    groceryList.items.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="text"
                                    value={item.name}
                                    onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                    required
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => handleInputChange(index, "quantity", parseFloat(e.target.value) || 1)}
                                    required
                                />
                            </td>
                            <td>{item.unit || "N/A"}</td>
                            <td>${item.price?.toFixed(2) || "0.00"}</td>
                            <td>
                                <div className="action-buttons">
                                    <button className="btn btn-danger" onClick={() => handleDeleteRow(index)}>
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="add-new-item">
                <input
                    type="text"
                    placeholder="Name"
                    value={newItem.name}
                    onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                    required
                />
                <input
                    type="number"
                    placeholder="Quantity"
                    value={newItem.quantity}
                    onChange={(e) => setNewItem({ ...newItem, quantity: parseFloat(e.target.value) || 1 })}
                    required
                />
                <div className="action-buttons">
                    <button onClick={handleAddRow} className="btn">
                        Add Item
                    </button>
                </div>
            </div>

            <h2 className="grocery-total">Total Cost: ${groceryList.total?.toFixed(2) || "0.00"}</h2>

            <div className="action-buttons">
                <button onClick={handleSaveChanges} className="btn">
                    Save Changes
                </button>
                <button onClick={handleImportIngredients} className="btn" disabled={importing}>
                    {importing ? "Importing..." : "Import Missing Ingredients"}
                </button>
                <button onClick={handleDeleteList} className="btn btn-danger">
                    Delete Grocery List
                </button>
            </div>
        </div>
    );
};

export default GroceryEdit;
