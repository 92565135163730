import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";


const AuthLogin = ({ onLogin }) => {
    const [usernameInput, setUsernameInput] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError("");

        try {
            const formData = new URLSearchParams();
            formData.append("username", usernameInput);
            formData.append("password", password);

            const response = await axiosInstance.post(`/auth/login`, formData,
                {
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                }
            );

            const { access_token } = response.data;
            const decodedToken = jwtDecode(access_token);

            // Save data in state and localStorage
            localStorage.setItem("authToken", access_token);
            localStorage.setItem("userId", decodedToken.sub); // Assuming `sub` contains the userId
            onLogin()
            // Redirect to user page
            navigate(`/user/${decodedToken.sub}`);
        } catch (err) {
            if (err.response && err.response.status === 429) {
                setError("Too many login attempts. Try again later.");
            } else {
                setError("Invalid username or password.");
            }
        }
    };

    return (
        <div className="auth-container">
            <h1>Login</h1>
            <form className="auth-form" onSubmit={handleLogin}>
                <input
                    type="text"
                    placeholder="Username"
                    value={usernameInput}
                    onChange={(e) => setUsernameInput(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <div className="action-buttons">
                    <button type="submit" className="btn">Login</button>
                </div>
            </form>
            <p>
                <Link to="/forgot-password">Forgot Password?</Link>
            </p>
            {error && <p className="error-text">{error}</p>}
        </div>
    );
};

export default AuthLogin;
