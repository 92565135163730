import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../../axiosInstance';
import "./List.css";


const PantryList = ({ userId }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axiosInstance.get(`/pantry/${userId}/items`);
                setItems(response.data.items);
            } catch (err) {
                console.error("Error fetching pantry items:", err);
                setError("Failed to fetch pantry items.");
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [userId]);

    if (loading) {
        return <p>Loading pantry items...</p>;
    }

    if (error) {
        return <p style={{ color: "red" }}>{error}</p>;
    }

    return (
        <div className="pantry-list">
            <h2>{userId}'s Pantry</h2>
            {items.length === 0 ? (
                <p>No items in your pantry.</p>
            ) : (
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Unit</th>
                        <th>Expiration Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item) => (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.quantity}</td>
                            <td>{item.unit}</td>
                            <td>{item.expiration_date}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
            <div className="action-buttons">
                <button onClick={() => navigate(`/user/${userId}/edit-pantry`)} className="btn" >
                    Edit Pantry
                </button>
            </div>
        </div>
    );
};

export default PantryList;
