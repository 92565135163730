import { useState } from "react";
import { useSearchParams } from "react-router-dom"; // For React Router
import axiosInstance from '../../axiosInstance';
import "./Auth.css";


const AuthPasswordReset = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        uppercase: false,
        number: false,
        specialChar: false,
    });
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const validatePassword = (password) => {
        setPasswordValidations({
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        });
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
        validatePassword(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        if (newPassword !== confirmPassword) {
            setMessage("Passwords do not match.");
            return;
        }

        if (!Object.values(passwordValidations).every(Boolean)) {
            setMessage("Password does not meet security requirements.");
            return;
        }

        try {
            const response = await axiosInstance.post(`/auth/reset-password`, {
                token: token,
                new_password: newPassword
            });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.detail || "An error occurred.");
        }
    };

    return (
        <div className="auth-container">
            <h2>Reset Password</h2>
            <form className="auth-form" onSubmit={handleSubmit}>
                <input
                    type="password"
                    placeholder="New password"
                    value={newPassword}
                    onChange={handlePasswordChange}
                    required
                />
                <ul className="password-requirements">
                    <li className={passwordValidations.length ? "valid" : ""}>At least 8 characters</li>
                    <li className={passwordValidations.uppercase ? "valid" : ""}>At least one uppercase letter</li>
                    <li className={passwordValidations.number ? "valid" : ""}>At least one number</li>
                    <li className={passwordValidations.specialChar ? "valid" : ""}>At least one special character (!@#$%^&*)</li>
                </ul>
                <input
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <div className="action-button">
                    <button className="btn" type="submit">Reset Password</button>
                </div>
            </form>
            {message && <p className={message.includes("error") ? "error-text" : "success-message"}>{message}</p>}
        </div>
    );
};

export default AuthPasswordReset;
