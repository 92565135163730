import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="app-footer">
            <div className="footer-content">
                <div className="links">
                    <a href="/privacy-policy">Privacy Policy</a>
                    <a href="/terms-of-service">Terms of Service</a>
                    <a
                        href="mailto:adrianalvarez.engineer@gmail.com?subject=Contact%20Us"
                        className="footer-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Contact Us
                    </a>
                </div>
                {/*<div className="social-media">*/}
                {/*    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">*/}
                {/*        Facebook*/}
                {/*    </a>*/}
                {/*    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">*/}
                {/*        Twitter*/}
                {/*    </a>*/}
                {/*    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">*/}
                {/*        Instagram*/}
                {/*    </a>*/}
                {/*</div>*/}
                <div className="copyright">
                    <p>&copy; {new Date().getFullYear()} Receipt2Recipe. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
