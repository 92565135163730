// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-recipe-form {
    /*border: 1px solid #ccc;*/
    /*border-radius: 8px;*/
    padding: 16px;
    margin-bottom: 16px;
}

.add-recipe-form .form-group {
    margin-bottom: 16px;
}

.add-recipe-form .ingredient {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.add-recipe-form .ingredient input {
    margin-right: 8px;
}

.add-recipe-form button {
    margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Recipe/Add.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".add-recipe-form {\n    /*border: 1px solid #ccc;*/\n    /*border-radius: 8px;*/\n    padding: 16px;\n    margin-bottom: 16px;\n}\n\n.add-recipe-form .form-group {\n    margin-bottom: 16px;\n}\n\n.add-recipe-form .ingredient {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n.add-recipe-form .ingredient input {\n    margin-right: 8px;\n}\n\n.add-recipe-form button {\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
