// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navbar Styling */
.navbar {
    display: flex;
    justify-content: center;
    padding: 10px 30px;
    border-radius: 12px;
}

.navbar-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px;
}

.navbar-item a {
    color: #E0E0E0;
    text-decoration: none;
    font-size: 18px;
    padding: 8px 12px;
    border-radius: 12px;
    transition: background-color 0.3s ease;
}

.navbar-item a:hover {
    color: #9B67E0;
}

.navbar-item .btn:hover {
    color: darkred;
}

.navbar-item {
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,SAAS;AACb;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,sCAAsC;AAC1C;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd","sourcesContent":["/* Navbar Styling */\n.navbar {\n    display: flex;\n    justify-content: center;\n    padding: 10px 30px;\n    border-radius: 12px;\n}\n\n.navbar-list {\n    display: flex;\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    gap: 10px;\n}\n\n.navbar-item a {\n    color: #E0E0E0;\n    text-decoration: none;\n    font-size: 18px;\n    padding: 8px 12px;\n    border-radius: 12px;\n    transition: background-color 0.3s ease;\n}\n\n.navbar-item a:hover {\n    color: #9B67E0;\n}\n\n.navbar-item .btn:hover {\n    color: darkred;\n}\n\n.navbar-item {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
