// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
    max-width: 400px;
    margin: auto auto;
    padding: 10px;
    text-align: center;
}

.auth-form {
    display: flex;
    flex-direction: column;
}

.auth-form input {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 15px;
}

.auth-form input:focus {
    outline: none;
    border-color: #9B67E0;
}

.error-text {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
}


.password-requirements {
    text-align: left;
    padding: 0;
    font-size: 14px;
    color: #555;
}

.password-requirements li {
    font-size: 14px;
    margin-bottom: 5px;
    color: red;
    transition: color 0.3s ease-in-out;

}

.password-requirements .valid {
    color: green;
    font-weight: bold;
}


.success-message {
    color: green;
    font-size: 14px;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/Auth.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;;AAGA;IACI,gBAAgB;IAChB,UAAU;IACV,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,kCAAkC;;AAEtC;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;;AAGA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".auth-container {\n    max-width: 400px;\n    margin: auto auto;\n    padding: 10px;\n    text-align: center;\n}\n\n.auth-form {\n    display: flex;\n    flex-direction: column;\n}\n\n.auth-form input {\n    padding-top: 2px;\n    padding-bottom: 2px;\n    margin-bottom: 15px;\n}\n\n.auth-form input:focus {\n    outline: none;\n    border-color: #9B67E0;\n}\n\n.error-text {\n    color: red;\n    font-size: 14px;\n    margin-top: 10px;\n    text-align: left;\n}\n\n\n.password-requirements {\n    text-align: left;\n    padding: 0;\n    font-size: 14px;\n    color: #555;\n}\n\n.password-requirements li {\n    font-size: 14px;\n    margin-bottom: 5px;\n    color: red;\n    transition: color 0.3s ease-in-out;\n\n}\n\n.password-requirements .valid {\n    color: green;\n    font-weight: bold;\n}\n\n\n.success-message {\n    color: green;\n    font-size: 14px;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
