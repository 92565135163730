import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from '../../axiosInstance';
import "./Edit.css";


const ReceiptEdit = () => {
    const { userId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [receiptItems, setReceiptItems] = useState(location.state?.receiptData || []);
    const [photoPreview, setPhotoPreview] = useState(location.state?.photo || null); // Photo preview from upload
    const [error, setError] = useState("");

    const handleInputChange = (index, field, value) => {
        const updatedItems = [...receiptItems];
        updatedItems[index][field] = value;
        setReceiptItems(updatedItems);
    };

    const handleAddRow = () => {
        setReceiptItems([...receiptItems, { name: "", quantity: 1, expiration_date: "" }]);
    };

    const handleDeleteRow = (index) => {
        const updatedItems = receiptItems.filter((_, i) => i !== index);
        setReceiptItems(updatedItems);
    };

    const handleClearPhoto = () => {
        if (photoPreview) {
            URL.revokeObjectURL(photoPreview); // Cleanup temporary URL
        }
        setPhotoPreview(null);
    };

    const handleSaveToPantry = async () => {
        const errors = [];
        receiptItems.forEach((item, index) => {
            if (!item.name) errors.push(`Name is required at row ${index + 1}`);
            if (!item.quantity || isNaN(item.quantity) || item.quantity <= 0) {
                errors.push(`Valid quantity is required at row ${index + 1}`);
            }
            if (!item.price || isNaN(item.price) || item.price < 0) {
                errors.push(`Valid price is required at row ${index + 1}`);
            }
        });

        if (errors.length > 0) {
            alert(errors.join("\n"));
            return;
        }

        try {
            for (const item of receiptItems) {
                if (!item.name || !item.quantity) {
                    throw new Error("Name and quantity are required for all items.");
                }

                const payload = {
                    name: item.name,
                    quantity: parseInt(item.quantity, 10), // Ensure it's an integer
                    unit: item.unit,
                    price: item.price,
                    expiration_date: item.expiration_date || null,
                    date_bought: item.date_bought,
                };

                await axiosInstance.post(`/pantry/${userId}/add`, payload, {
                    headers: { "Content-Type": "application/json" },
                });
            }
            alert("Items saved to pantry!");
            navigate(`/user/${userId}`);
        } catch (err) {
            console.error("Error saving items to pantry:", err);
            setError(err.message || "Failed to save items to pantry.");
        }
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (photoPreview) {
                URL.revokeObjectURL(photoPreview);
            }
        };
    }, [photoPreview]);

    return (
        <div className="edit-receipt">
            <h1>Edit Receipt Items</h1>
            {photoPreview && (
                <div className="photo-preview">
                    <h4>Uploaded Receipt:</h4>
                    <img src={photoPreview} alt="Receipt Preview"/>
                    <div className="action-buttons">
                        <button onClick={handleClearPhoto} className="btn btn-danger">Clear Photo</button>
                    </div>
                </div>
            )}
            {error && <p className="error">{error}</p>}
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Expiration Date</th>
                    <th>Date Bought</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {receiptItems.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <input
                                type="text"
                                value={item.name}
                                onChange={(e) => handleInputChange(index, "name", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={item.quantity}
                                onChange={(e) => handleInputChange(index, "quantity", parseFloat(e.target.value))}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={item.unit}
                                onChange={(e) => handleInputChange(index, "unit", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="date"
                                value={item.expiration_date}
                                onChange={(e) => handleInputChange(index, "expiration_date", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="date"
                                value={item.date_bought || new Date().toISOString().split("T")[0]}
                                onChange={(e) => handleInputChange(index, "date_bought", e.target.value)}
                            />
                        </td>
                        <td>
                            <div className="action-buttons">
                                <button className="btn btn-danger" onClick={() => handleDeleteRow(index)}>
                                    Delete
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="action-buttons">
                <button className="btn" onClick={handleAddRow}>
                    Add Item
                </button>
                <button className="btn" onClick={handleSaveToPantry}>
                    Save to Pantry
                </button>
            </div>
        </div>
    );
};

export default ReceiptEdit;
