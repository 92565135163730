// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Saved Grocery Lists Styling */
.saved-list {
    padding: 10px;
}

.saved-list h2 {
    text-align: center;
}

.saved-list h3 {
    color: darkgrey;
}

.saved-list ul {
    list-style-type: none;
}

.saved-list ul li {
    padding: 10px 15px;
    border-radius: 8px;
    background-color: darkgrey;
    transition: background-color 0.3s ease;
}

.saved-list strong {
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Grocery/ListLatest.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,0BAA0B;IAC1B,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":["/* Saved Grocery Lists Styling */\n.saved-list {\n    padding: 10px;\n}\n\n.saved-list h2 {\n    text-align: center;\n}\n\n.saved-list h3 {\n    color: darkgrey;\n}\n\n.saved-list ul {\n    list-style-type: none;\n}\n\n.saved-list ul li {\n    padding: 10px 15px;\n    border-radius: 8px;\n    background-color: darkgrey;\n    transition: background-color 0.3s ease;\n}\n\n.saved-list strong {\n    color: #333;\n    margin-bottom: 10px;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
