import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import RecipeManage from "./components/Recipe/Manage";
import PantryEdit from "./components/Pantry/Edit"
import PantryManage from "./components/Pantry/Manage";
import ReceiptEdit from "./components/Receipt/Edit";
import AuthLogin from "./components/Auth/Login";
import AuthRegister from "./components/Auth/Register";
import AuthUsersManage from "./components/Auth/UsersManage";
import AuthPasswordForgot from "./components/Auth/PasswordForgot";
import AuthPasswordReset from "./components/Auth/PasswordReset";
import CollabManage from "./components/Collab/Manage";
import WeeklyMealPlannerManage from "./components/WeeklyMealPlanner/Manage";
import GroceryEdit from "./components/Grocery/Edit"

import Home from "./components/Home";
import UserPage from "./components/UserPage";
import axiosInstance from "./axiosInstance";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { jwtDecode } from "jwt-decode";
import "./App.css";
import "./components/Auth/Auth.css";


const ProtectedRoute = ({ auth, children }) => {
    if (!auth) {
        return <Navigate to="/auth" />;
    }
    return children;
};


function App() {
    const [auth, setAuth] = useState(localStorage.getItem("authToken"));
    const [userId, setUserId] = useState(localStorage.getItem("userId"));
    const [username, setUsername] = useState(localStorage.getItem("username"));
    const [pantryItems, setPantryItems] = useState([]);

    const handleLogin = () => {
        setAuth(localStorage.getItem("authToken"));
        setUserId(localStorage.getItem("userId"));
    };

    const handleLogout = async () => {
        try {
            await axiosInstance.post(`/auth/logout`);
        } catch (error) {
            console.error("Logout error:", error);
        }
        localStorage.removeItem("authToken");
        localStorage.removeItem("userId");
        setAuth(null);
    };

    useEffect(() => {
        if (auth) {
            axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${auth}`;
        } else {
            delete axiosInstance.defaults.headers.common["Authorization"];
        }
    }, [auth]);


    const fetchPantryItems = useCallback(async () => {
        if (userId) {
            try {
                const response = await axiosInstance.get(`/pantry/${userId}/items`);
                setPantryItems(response.data.items || []);
            } catch (error) {
                console.error("Failed to fetch pantry items:", error);
            }
        }
    }, [userId]);

    useEffect(() => {
        fetchPantryItems();
    }, [userId, fetchPantryItems]);

    return (
        <BrowserRouter>
            <div className="app-wrapper">
                <NavBar auth={auth} setAuth={setAuth} userId={userId} username={username} onLogout={handleLogout}/>
                <main>
                    <Routes>
                        {/*<Route path="/" element={auth ? <Navigate to="/" /> : <AuthLogin onLogin={handleLogin} />} />*/}
                        {/*<Route path="/dashboard" element={authToken ? <Dashboard onLogout={handleLogout} /> : <Navigate to="/" />} />*/}
                        <Route path="/" element={<Home/>}/>
                        <Route path="/auth" element={<AuthLogin onLogin={handleLogin}/>}/>
                        <Route path="/forgot-password" element={<AuthPasswordForgot />} />
                        <Route path="/reset-password" element={<AuthPasswordReset />} />
                        <Route path="/users" element={<AuthUsersManage/>}/>
                        <Route
                            path="/user/:userId/pantry"
                            element={
                                <ProtectedRoute auth={auth}>
                                    <PantryManage userId={userId} username={username}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/user/:userId/recipes"
                            element={<RecipeManage userId={userId} pantryItems={pantryItems}/>}
                        />
                        <Route
                            path="/user/:userId"
                            element={
                                userId ? <UserPage userId={userId} username={username}/> : <Navigate to="/auth"/>
                            }
                        />
                        <Route path="/user/:userId/edit-receipt" element={<ReceiptEdit/>}/>
                        <Route
                            path="/user/:userId/collaboration"
                            element={<CollabManage userId={userId}/>}
                        />
                        <Route path="/user/:userId/edit-pantry" element={
                            <PantryEdit fetchPantryItems={fetchPantryItems}/>
                        }/>
                        <Route path="/user/:userId/meal-planner" element={
                            <WeeklyMealPlannerManage userId={userId}/>
                        }/>
                        <Route path="/register" element={<AuthRegister/>}/>
                        <Route
                            path="/user/:userId/grocery-list"
                            element={<GroceryEdit userId={userId} />}
                        />
                    </Routes>
                </main>
                <Footer/>
            </div>
        </BrowserRouter>
);
}

export default App;
