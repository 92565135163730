// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Upload Component Styling */
.upload-component {
    margin: 20px 0;
    padding: 20px;
    background-color: darkgrey;
    border-radius: 12px;
}

.upload-component h2 {
    text-align: center;
}

.upload-component input[type="url"] ,
.upload-component input[type="file"] {
    display: block;
    margin: 20px auto;
    padding: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
    width: 80%;
    font-size: 16px;
}

.upload-component .upload-error {
    color: darkred;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Receipt/Add.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,cAAc;IACd,aAAa;IACb,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["/* Upload Component Styling */\n.upload-component {\n    margin: 20px 0;\n    padding: 20px;\n    background-color: darkgrey;\n    border-radius: 12px;\n}\n\n.upload-component h2 {\n    text-align: center;\n}\n\n.upload-component input[type=\"url\"] ,\n.upload-component input[type=\"file\"] {\n    display: block;\n    margin: 20px auto;\n    padding: 10px;\n    border: 1px solid #E0E0E0;\n    border-radius: 12px;\n    width: 80%;\n    font-size: 16px;\n}\n\n.upload-component .upload-error {\n    color: darkred;\n    font-size: 14px;\n    margin-bottom: 15px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
