// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add Recipe from URL Styling */
.recipe-card {
    border: 1px solid darkgrey;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 25px;
}

.recipe-card .form-group {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.recipe-card .form-group2 {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.recipe-card .ingredient {
    display: flex;
    /*align-items: center;*/
    margin-bottom: 12px;
}

.recipe-card .ingredient input {
    margin-right: 12px;
    padding: 10px;
    border-radius: 12px;
    font-size: 16px;
}

.error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Recipe/AddFromUrl.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":["/* Add Recipe from URL Styling */\n.recipe-card {\n    border: 1px solid darkgrey;\n    border-radius: 10px;\n    padding: 10px;\n    margin-bottom: 25px;\n}\n\n.recipe-card .form-group {\n    display: grid;\n    grid-template-columns: max-content 1fr;\n    grid-gap: 10px;\n    margin-bottom: 10px;\n}\n\n.recipe-card .form-group2 {\n    display: grid;\n    grid-template-columns: 1fr max-content;\n    grid-gap: 10px;\n    margin-bottom: 10px;\n}\n\n.recipe-card .ingredient {\n    display: flex;\n    /*align-items: center;*/\n    margin-bottom: 12px;\n}\n\n.recipe-card .ingredient input {\n    margin-right: 12px;\n    padding: 10px;\n    border-radius: 12px;\n    font-size: 16px;\n}\n\n.error {\n    color: red;\n    font-weight: bold;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
