// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pantry List Styling */
.pantry-list {
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.pantry-list table {
    width: 100%;
    border-collapse: collapse;
}

.pantry-list table th, .pantry-list table td {
    /*padding: 15px;*/
    font-size: 16px;
    /*text-align: left;*/
    border-bottom: 1px solid darkgrey;
}

.pantry-list table thead th {
    background-color: darkgrey;
    color: #555;
}

.pantry-list table tbody tr {
    transition: background-color 0.3s ease;
}

.pantry-list table tbody tr:hover {
    background-color: #9B67E0;
}


.pantry-list .error {
    color: #e74c3c;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .pantry-list {
        padding: 15px;
    }

    .pantry-list h2 {
        font-size: 24px;
    }

    .pantry-list table th, .pantry-list table td {
        padding: 10px;
    }

    .pantry-list button {
        font-size: 16px;
        padding: 10px 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Pantry/List.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,aAAa;IACb,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,oBAAoB;IACpB,iCAAiC;AACrC;;AAEA;IACI,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA,uBAAuB;AACvB;IACI;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;QACf,kBAAkB;IACtB;AACJ","sourcesContent":["/* Pantry List Styling */\n.pantry-list {\n    padding: 20px;\n    border-radius: 12px;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n}\n\n.pantry-list table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.pantry-list table th, .pantry-list table td {\n    /*padding: 15px;*/\n    font-size: 16px;\n    /*text-align: left;*/\n    border-bottom: 1px solid darkgrey;\n}\n\n.pantry-list table thead th {\n    background-color: darkgrey;\n    color: #555;\n}\n\n.pantry-list table tbody tr {\n    transition: background-color 0.3s ease;\n}\n\n.pantry-list table tbody tr:hover {\n    background-color: #9B67E0;\n}\n\n\n.pantry-list .error {\n    color: #e74c3c;\n    font-weight: bold;\n    text-align: center;\n    margin-top: 20px;\n}\n\n/* Responsive Styling */\n@media (max-width: 768px) {\n    .pantry-list {\n        padding: 15px;\n    }\n\n    .pantry-list h2 {\n        font-size: 24px;\n    }\n\n    .pantry-list table th, .pantry-list table td {\n        padding: 10px;\n    }\n\n    .pantry-list button {\n        font-size: 16px;\n        padding: 10px 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
