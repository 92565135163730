import React, { useState, useEffect } from "react";
import axiosInstance from '../../axiosInstance';
import RecipeList from "./List";
import RecipeAdd from "./Add";
import RecipeAddFromUrl from "./AddFromUrl";
import RecipeReview from "./Review";


const RecipeManage = ({ userId, pantryItems }) => {
    const [allRecipes, setAllRecipes] = useState([]);
    const [selectedRecipes, setSelectedRecipes] = useState([]);
    const [favoriteRecipes, setFavoriteRecipes] = useState([]);
    const [remainingRecipes, setRemainingRecipes] = useState([]);
    const [showAddRecipeForm, setShowAddRecipeForm] = useState(false);
    const [showAddRecipeFromUrlForm, setShowAddRecipeFromUrlForm] = useState(false);
    const [editingRecipe, setEditingRecipe] = useState(null);
    const [suggestedRecipes, setSuggestedRecipes] = useState([]);
    const [isReviewingAIRecipes, setIsReviewingAIRecipes] = useState(false);
    const [message, setMessage] = useState("");

    const fetchAIRecipes = async () => {
        try {
            const response = await axiosInstance.post(`/recipes/${userId}/ai-suggestions`);
            setMessage(response.data.message);
            setSuggestedRecipes(response.data.recipes);
            setIsReviewingAIRecipes(true);
        } catch (error) {
            console.error("Error fetching AI recipes:", error);
            alert("Failed to fetch AI-suggested recipes. Please try again.");
        }
    };

    const addRecipe = (recipe) => {
        setAllRecipes([...allRecipes, recipe]);
        setShowAddRecipeForm(false);
        setShowAddRecipeFromUrlForm(false);
    };

    const addMessage = (message) => {
        setMessage(message);
    };

    const handleToggleFavorite = async (recipe) => {
        try {
            const updatedRecipe = { ...recipe, favorited: !recipe.favorited };
            const response = await axiosInstance.put(`/recipes/${userId}`, updatedRecipe);

            // Update the recipe's favorite status locally
            const updatedRecipes = allRecipes.map((r) =>
                r.id === recipe.id ? { ...r, favorited: response.data.favorited } : r
            );

            // Re-categorize recipes
            setAllRecipes(updatedRecipes);
            setFavoriteRecipes(updatedRecipes.filter((r) => r.favorited));
            setRemainingRecipes(updatedRecipes.filter((r) => !r.favorited && !r.selected));
        } catch (error) {
            console.error("Error toggling favorite status:", error);
        }
    };

    const handleSelectRecipe = async (recipe) => {
        try {
            const updatedRecipe = { ...recipe, selected: !recipe.selected };
            await axiosInstance.put(`/recipes/${userId}`, updatedRecipe);
            const updatedRecipes = allRecipes.map((r) => (r.id === recipe.id ? updatedRecipe : r));
            setAllRecipes(updatedRecipes);
            setSelectedRecipes(updatedRecipes.filter((r) => r.selected));
        } catch (error) {
            console.error("Error updating recipe selection:", error);
        }
    };

    const handleEditRecipe = (recipe) => {
        setEditingRecipe(recipe);
    };

    const handleDeleteRecipe = async (recipeId) => {
        try {
            await fetch(`/recipes/${userId}/${recipeId}`, { method: "DELETE" });
            const updatedRecipes = allRecipes.filter((recipe) => recipe.id !== recipeId);
            setAllRecipes(updatedRecipes);
            setSelectedRecipes(updatedRecipes.filter((r) => r.selected));
        } catch (error) {
            console.error("Error deleting recipe:", error);
        }
    };

    const handleUpdateRecipe = async (updatedRecipe) => {
        try {
            await axiosInstance.put(`/recipes/${updatedRecipe.id}`, updatedRecipe);
            const updatedRecipes = allRecipes.map((recipe) =>
                recipe.id === updatedRecipe.id ? updatedRecipe : recipe
            );
            setAllRecipes(updatedRecipes);
            setEditingRecipe(null);
        } catch (error) {
            console.error("Error updating recipe:", error);
        }
    };

    // const handleApproveRecipes = async (recipes) => {
    //     try {
    //         for (const recipe of recipes) {
    //             await axiosInstance.post(`/recipes/${userId}/add`, recipe);
    //         }
    //         alert("Recipes approved and added successfully!");
    //         setIsReviewingAIRecipes(false); // Close the review component
    //         //     TODO fetch recipes here to update it without needing refresh
    //     } catch (error) {
    //         console.error("Error approving recipes:", error);
    //         alert("Failed to approve recipes.");
    //     }
    // };

    // const handleRegenerateRecipes = () => {
    //     fetchAIRecipes();
    // };

    // const handleCancelAIRecipes = () => {
    //     setSuggestedRecipes([]);
    //     setIsReviewingAIRecipes(false);
    // };

    const onUpdateInteraction = async (recipe, updates) => {
        try {
            const updatedRecipe = { ...recipe, ...updates };
            const recipeId = updatedRecipe.id
            const response = await axiosInstance.put(`/recipes/${userId}`, updatedRecipe);

            const updatedInteraction = response.data;

            setAllRecipes((prevRecipes) =>
                prevRecipes.map((recipe) =>
                    recipe.id === recipeId ? { ...recipe, ...updatedInteraction } : recipe
                )
            );

            // Update categorized lists
            setSelectedRecipes((prevSelected) =>
                prevSelected.map((recipe) =>
                    recipe.id === recipeId ? { ...recipe, ...updatedInteraction } : recipe
                )
            );

            setFavoriteRecipes((prevFavorites) =>
                prevFavorites.map((recipe) =>
                    recipe.id === recipeId ? { ...recipe, ...updatedInteraction } : recipe
                )
            );

            setRemainingRecipes((prevRemaining) =>
                prevRemaining.map((recipe) =>
                    recipe.id === recipeId ? { ...recipe, ...updatedInteraction } : recipe
                )
            );
        } catch (error) {
            console.error("Error updating recipe interaction:", error);
        }
    };

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                const response = await axiosInstance.get(`/recipes/${userId}`);
                const recipes = response.data.recipes;
                const selected = recipes.filter((recipe) => recipe.selected);
                const favorited = recipes.filter((recipe) => recipe.favorited);
                const remaining = recipes.filter((recipe) => !recipe.selected && !recipe.favorited);

                setAllRecipes(recipes);
                setSelectedRecipes(selected);
                setFavoriteRecipes(favorited);
                setRemainingRecipes(remaining);
            } catch (error) {
                console.error("Error fetching recipes:", error);
            }
        };

        fetchRecipes();
    }, [userId]);

    return (
        <div className="page-base">
            <h1>Manage Recipes</h1>
            {message && <p className="info-message">{message}</p>}

            <div className="action-buttons">
                <button onClick={() => setShowAddRecipeForm(!showAddRecipeForm)} className="btn">
                    {showAddRecipeForm ? "Cancel" : "Create Recipe"}
                </button>
                <button onClick={() => setShowAddRecipeFromUrlForm(!showAddRecipeFromUrlForm)} className="btn btn-green">
                    {showAddRecipeFromUrlForm ? "Cancel" : "Add Recipe From URL"}
                </button>
                <button onClick={fetchAIRecipes} className="btn btn-blue">
                    Get Suggested Recipes
                </button>
            </div>

            {showAddRecipeForm && <RecipeAdd userId={userId} addRecipe={addRecipe} />}
            {showAddRecipeFromUrlForm &&
                <RecipeAddFromUrl userId={userId} addRecipe={addRecipe} addMessage={addMessage}/>}

            {isReviewingAIRecipes && (
                <RecipeReview
                    userId={userId}
                    suggestedRecipes={suggestedRecipes}
                    onApprove={(recipes) => {
                        recipes.forEach((recipe) => addRecipe(recipe));
                        setIsReviewingAIRecipes(false);
                    }}
                    onRegenerate={fetchAIRecipes}
                    onCancel={() => setIsReviewingAIRecipes(false)}
                />
            )}

            <section>
                <h2>Selected Recipes</h2>
                <RecipeList
                    recipes={selectedRecipes}
                    pantryItems={pantryItems}
                    onSelectRecipe={handleSelectRecipe}
                    onEditRecipe={handleEditRecipe}
                    onUpdateRecipe={handleUpdateRecipe}
                    onDeleteRecipe={handleDeleteRecipe}
                    onToggleFavorite={handleToggleFavorite}
                    editingRecipe={editingRecipe}
                    onUpdateInteraction={onUpdateInteraction}
                />
            </section>

            <section>
                <h2>Favorite Recipes</h2>
                <RecipeList
                    recipes={favoriteRecipes}
                    pantryItems={pantryItems}
                    onSelectRecipe={handleSelectRecipe}
                    onEditRecipe={handleEditRecipe}
                    onUpdateRecipe={handleUpdateRecipe}
                    onDeleteRecipe={handleDeleteRecipe}
                    onToggleFavorite={handleToggleFavorite}
                    editingRecipe={editingRecipe}
                    onUpdateInteraction={onUpdateInteraction}
                />
            </section>

            <section>
                <h2>All Recipes</h2>
                <RecipeList
                    recipes={remainingRecipes}
                    pantryItems={pantryItems}
                    onSelectRecipe={handleSelectRecipe}
                    onEditRecipe={handleEditRecipe}
                    onUpdateRecipe={handleUpdateRecipe}
                    onDeleteRecipe={handleDeleteRecipe}
                    onToggleFavorite={handleToggleFavorite}
                    editingRecipe={editingRecipe}
                    onUpdateInteraction={onUpdateInteraction}
                />
            </section>

        </div>
    );
};

export default RecipeManage;
