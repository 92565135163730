import React, { useState } from "react";
import axios from "../../axiosInstance";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = axios.defaults.baseURL;

const AuthRegister = () => {
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({ username: "", email: "", password: "" });
    const navigate = useNavigate();
    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        uppercase: false,
        number: false,
        specialChar: false,
    });

    const validatePassword = (password) => {
        const validations = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };
        setPasswordValidations(validations);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });

        if (name === "password") {
            validatePassword(value);
        }
    };


    const handleRegister = async (e) => {
        e.preventDefault();
        setErrors({ username: "", email: "", password: "" });

        try {
            await axios.post(`${API_BASE_URL}/auth/register`, formData);
            alert("Registration successful! Please log in.");
            setFormData({ username: "", full_name: "", email: "", password: "" });
            navigate("/auth");
        } catch (error) {
            if (error.response && error.response.data.detail) {
                const { field, message } = error.response.data.detail;
                setErrors((prevErrors) => ({ ...prevErrors, [field]: message }));
            } else {
                alert("Registration failed. Please try again.");
            }
        }
    };

    return (
        <div className="auth-container">
            <h2>Register</h2>
            <form onSubmit={handleRegister}>
                <div className="auth-form">
                    <label>Username</label>
                    <input type="text" name="username" value={formData.username} onChange={handleChange} required />
                    {errors.username && <p className="error-text">{errors.username}</p>}
                </div>

                <div className="auth-form">
                    <label>Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    {errors.email && <p className="error-text">{errors.email}</p>}
                </div>

                <div className="auth-form">
                    <label>Password</label>
                    <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                    {errors.password && <p className="error-text">{errors.password}</p>}
                </div>

                <ul className="password-requirements">
                    <li className={passwordValidations.length ? "valid" : ""}>
                        At least 8 characters
                    </li>
                    <li className={passwordValidations.uppercase ? "valid" : ""}>
                        Contains at least one uppercase letter
                    </li>
                    <li className={passwordValidations.number ? "valid" : ""}>
                        Contains at least one number
                    </li>
                    <li className={passwordValidations.specialChar ? "valid" : ""}>
                        Contains at least one special character (!@#$%^&*)
                    </li>
                </ul>

                <div className="action-buttons">
                    <button type="submit" className="btn">Register</button>
                </div>
            </form>
        </div>
    );
};

export default AuthRegister;
