import React, { useState } from "react";
import axiosInstance from '../../axiosInstance';


const RecipeReview = ({ userId, suggestedRecipes, onRegenerate, onCancel, onApprove }) => {
    const [recipes, setRecipes] = useState(suggestedRecipes);
    const [error, setError] = useState("");

    const addRecipeToDatabase = (recipe) => {
        alert(`Recipe "${recipe.name}" added to your database.`);
    };
    
    const handleInputChange = (recipeIndex, field, value) => {
        const updatedRecipes = [...recipes];
        updatedRecipes[recipeIndex][field] = value;
        setRecipes(updatedRecipes);
    };

    const handleIngredientChange = (recipeIndex, ingredientIndex, field, value) => {
        const updatedRecipes = [...recipes];
        updatedRecipes[recipeIndex].ingredients[ingredientIndex][field] = value;
        setRecipes(updatedRecipes);
    };

    const handleStepChange = (recipeIndex, stepIndex, value) => {
        const updatedRecipes = [...recipes];
        updatedRecipes[recipeIndex].steps[stepIndex] = value;
        setRecipes(updatedRecipes);
    };

    const handleAddIngredient = (recipeIndex) => {
        const updatedRecipes = [...recipes];
        updatedRecipes[recipeIndex].ingredients.push({ name: "", quantity: "", unit: "" });
        setRecipes(updatedRecipes);
    };

    const handleAddStep = (recipeIndex) => {
        const updatedRecipes = [...recipes];
        updatedRecipes[recipeIndex].steps.push("");
        setRecipes(updatedRecipes);
    };

    const handleRemoveIngredient = (recipeIndex, ingredientIndex) => {
        const updatedRecipes = [...recipes];
        updatedRecipes[recipeIndex].ingredients.splice(ingredientIndex, 1);
        setRecipes(updatedRecipes);
    };

    const handleRemoveStep = (recipeIndex, stepIndex) => {
        const updatedRecipes = [...recipes];
        updatedRecipes[recipeIndex].steps.splice(stepIndex, 1);
        setRecipes(updatedRecipes);
    };

    const handleApproveRecipe = async (recipeIndex) => {
        try {
            const recipe = recipes[recipeIndex];
            const response = await axiosInstance.post(`/recipes/${userId}/add`, recipe, {
                headers: { "Content-Type": "application/json" },
            });
            addRecipeToDatabase(response.data.recipe);
            const updatedRecipes = recipes.filter((_, index) => index !== recipeIndex);
            setRecipes(updatedRecipes);
        } catch (err) {
            console.error("Error approving recipe:", err);
            setError("Failed to approve recipe.");
        }
    };

    const handleApprove = () => {
        onApprove(recipes);
    };

    if (recipes.length === 0) {
        return <p>No AI-generated recipes to review.</p>;
    }

    return (
        <div className="edit-recipe-form">
            <h2>Review AI-Generated Recipes</h2>
            {error && <p className="error">{error}</p>}
            {recipes.map((recipe, recipeIndex) => (
                <div key={recipeIndex} className="recipe-card">
                    <div className="form-group">
                        <h4>Title:</h4>
                        <input
                            type="text"
                            value={recipe.name}
                            onChange={(e) => handleInputChange(recipeIndex, "name", e.target.value)}
                            placeholder="Recipe Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <h4>Description:</h4>
                        <textarea
                            value={recipe.description}
                            onChange={(e) => handleInputChange(recipeIndex, "description", e.target.value)}
                            placeholder="Recipe Description"
                        />
                    </div>
                    <div className="form-group3">
                        <h4>Ingredients:</h4>
                        {recipe.ingredients.map((ingredient, ingredientIndex) => (
                            <div key={ingredientIndex} className="ingredient">
                                <input
                                    type="number"
                                    value={ingredient.quantity}
                                    onChange={(e) =>
                                        handleIngredientChange(recipeIndex, ingredientIndex, "quantity", e.target.value)
                                    }
                                    placeholder="Quantity"
                                />
                                <input
                                    type="text"
                                    value={ingredient.unit}
                                    onChange={(e) =>
                                        handleIngredientChange(recipeIndex, ingredientIndex, "unit", e.target.value)
                                    }
                                    placeholder="Unit"
                                />
                                <input
                                    type="text"
                                    value={ingredient.name}
                                    onChange={(e) =>
                                        handleIngredientChange(recipeIndex, ingredientIndex, "name", e.target.value)
                                    }
                                    placeholder="Ingredient Name"
                                    required
                                />
                                <div className="action-buttons">
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => handleRemoveIngredient(recipeIndex, ingredientIndex)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="action-buttons">
                        <button className="btn" onClick={() => handleAddIngredient(recipeIndex)}>Add Ingredient</button>
                    </div>
                    <h4>Steps</h4>
                    {recipe.steps.map((step, stepIndex) => (
                        <div key={stepIndex} className="form-group2">
                            <textarea
                                value={step}
                                onChange={(e) => handleStepChange(recipeIndex, stepIndex, e.target.value)}
                                placeholder={`Step ${stepIndex + 1}`}
                            />
                            <div className="action-buttons">
                                <button
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveStep(recipeIndex, stepIndex)}
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="action-buttons">
                        <button className="btn" onClick={() => handleAddStep(recipeIndex)}>Add Step</button>
                        <button
                            className="btn"
                            onClick={() => handleApproveRecipe(recipeIndex)}
                        >
                            Approve & Add Recipe
                        </button>
                    </div>
                </div>
            ))}
            <div className="action-buttons">
                <button className="btn" onClick={onRegenerate}>
                    Regenerate List
                </button>
                <button className="btn btn-danger" onClick={onCancel}>
                    Cancel
                </button>
                <button className="btn" onClick={handleApprove}>
                    Approve Recipes
                </button>
            </div>
        </div>
    );
};

export default RecipeReview;
