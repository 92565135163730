import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ReceiptAdd from '../Receipt/Add';
import PantryList from "./List";
import './Manage.css';


// TODO still breaks when refreshed
// TODO make expiring section a component and then link it to the user page
// const PantryItemsList = ({ items, title, className, warningType }) => {
//     return items.length > 0 ? (
//         <div className={className}>
//             <h2>{title}</h2>
//             <ul>
//                 {items.map((item) => (
//                     <li key={item.id}>
//                         <strong>{item.name}</strong> - {item.quantity}
//                         {item.expiration_date && ` (Expires on: ${item.expiration_date.split("T")[0]})`}
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     ) : null;
// };
// TODO make a running low section
const PantryManage = ({ userId, username}) => {
    //TODO make an object for item
    const location = useLocation();
    const [expiringItems, setExpiringItems] = useState([]);
    const [expiredItems, setExpiredItems] = useState([]);
    const [showUploadComponent, setShowUploadComponent] = useState(false);
    const [message, setMessage] = useState(location.state?.message || "");

    // useEffect(() => {
    //     if (message) {
    //         toast.info(message);
    //         setMessage(""); // Clear message after displaying
    //     }
    // }, [message]);

    useEffect(() => {
        const fetchAndFilterItems = async () => {
            try {
                const response = await axiosInstance.get(`/pantry/${userId}/items`);
                const items = response.data.items;
                filterExpiringItems(items);
            } catch (error) {
                console.error("Error fetching pantry items:", error);
                toast.error("Error fetching pantry items");
            }
        };

        if (userId) {
            fetchAndFilterItems();
        }
    }, [userId]);



    const filterExpiringItems = (items) => {
        const today = new Date();
        const expiringDate = new Date();
        expiringDate.setDate(expiringDate.getDate() + 7);

        const expiring = items.filter(
            (item) =>
                item.expiration_date &&
                new Date(item.expiration_date) <= expiringDate &&
                new Date(item.expiration_date) >= today
        );

        const expired = items.filter(
            (item) =>
                item.expiration_date && new Date(item.expiration_date) < today
        );

        setExpiringItems(expiring);
        setExpiredItems(expired);
    };

    useEffect(() => {
        // Display warnings for expiring and expired items
        expiringItems.forEach((item) => toast.warn(`Item "${item.name}" is expiring soon!`));
        expiredItems.forEach((item) => toast.error(`Item "${item.name}" has expired!`));
    }, [expiringItems, expiredItems]);

    const handleUploadComplete = () => {
        setShowUploadComponent(false); // Hide the upload form after submission
        toast.success("Receipt uploaded successfully!");
    };

    return (
        <div className="page-base">
            {/*<ToastContainer />*/}
            <h1>Pantry Inventory</h1>
            {message && <p className="info-message">{message}</p>}

            <div className="action-buttons">
                <button onClick={() => setShowUploadComponent(!showUploadComponent)} className="btn">
                    {showUploadComponent ? "Cancel Upload" : "Upload Receipt"}
                </button>
            </div>

            {showUploadComponent && (
                <ReceiptAdd userId={userId} username={username} onUploadComplete={handleUploadComplete} />
            )}

            <PantryList userId={userId}/>

            {/* Expiring Soon Section */}
            {expiringItems.length > 0 && (
                <div className="expiring-items">
                    <h2>Expiring Soon</h2>
                    <ul>
                        {expiringItems.map((item) => (
                            <li key={item.id}>
                                <strong>{item.name}</strong> - {item.quantity}
                                {item.expiration_date && ` (Expires on: ${item.expiration_date.split("T")[0]})`}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Expired Items Section */}
            {expiredItems.length > 0 && (
                <div className="expired-items">
                    <h2>Expired Items</h2>
                    <ul>
                        {expiredItems.map((item) => (
                            <li key={item.id}>
                                <strong>{item.name}</strong> - {item.quantity}
                                {item.expiration_date && ` (Expired on: ${item.expiration_date.split("T")[0]})`}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default PantryManage;
