import React, { useState } from "react";
import axiosInstance from '../../axiosInstance';
import "./Add.css";


const RecipeAdd = ({ userId, addRecipe }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState(""); // Added for recipe URL
    const [ingredients, setIngredients] = useState([{ name: "", quantity: "", unit: "" }]);
    const [steps, setSteps] = useState([""]);
    const [error, setError] = useState("");
    const [servings, setServings] = useState(1);

    const handleIngredientChange = (index, e) => {
        const updatedIngredients = ingredients.map((ingredient, i) =>
            i === index
                ? { ...ingredient, [e.target.name]: e.target.name === "quantity" ? parseFloat(e.target.value) || null : e.target.value }
                : ingredient
        );
        setIngredients(updatedIngredients);
    };

    const handleAddIngredient = () => {
        setIngredients([...ingredients, { name: "", quantity: "", unit: "" }]);
    };

    const handleRemoveIngredient = (index) => {
        setIngredients(ingredients.filter((_, i) => i !== index));
    };

    const handleStepChange = (index, e) => {
        const updatedSteps = steps.map((step, i) =>
            i === index ? e.target.value : step
        );
        setSteps(updatedSteps);
    };

    const handleAddStep = () => {
        setSteps([...steps, ""]);
    };

    const handleRemoveStep = (index) => {
        setSteps(steps.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = [];

        if (!name) errors.push("Recipe name is required.");
        if (ingredients.some((ingredient) => !ingredient.name || !ingredient.quantity)) {
            errors.push("Each ingredient must have a name and a valid quantity.");
        }

        if (errors.length > 0) {
            alert(errors.join("\n"));
            return;
        }

        try {
            const newRecipe = { name, description, url, ingredients, steps, servings };
            const response = await axiosInstance.post(`/recipes/${userId}/add`, newRecipe);
            addRecipe(response.data.recipe);
            setName("");
            setDescription("");
            setUrl("");
            setIngredients([{ name: "", quantity: "", unit: "" }]);
            setSteps([""]);
            setError("");
            setServings(1);
        } catch (error) {
            console.error("Error adding recipe:", error);
            setError("Failed to add recipe. Please try again.");
        }
    };

    return (
        <form className="add-recipe-form" onSubmit={handleSubmit}>
            <h2>Add a New Recipe</h2>
            {error && <p className="error">{error}</p>}
            <div className="form-group">
                <label>Recipe Name:</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Description:</label>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Servings:</label>
                <input
                    type="number"
                    min="1"
                    value={servings}
                    onChange={(e) => setServings(parseInt(e.target.value, 10))}
                    required
                />
            </div>
            <div className="form-group">
                <label>Recipe URL (optional):</label>
                <input
                    type="url"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="https://example.com/recipe"
                />
            </div>
            <div className="form-group">
                <label>Ingredients:</label>
                {ingredients.map((ingredient, index) => (
                    <div key={index} className="ingredient">
                        <input
                            type="number"
                            name="quantity"
                            placeholder="Quantity"
                            value={ingredient.quantity}
                            onChange={(e) => handleIngredientChange(index, e)}
                            required
                        />
                        <input
                            type="text"
                            name="unit"
                            placeholder="Unit"
                            value={ingredient.unit}
                            onChange={(e) => handleIngredientChange(index, e)}
                            required
                        />
                        <input
                            type="text"
                            name="name"
                            placeholder="Ingredient Name"
                            value={ingredient.name}
                            onChange={(e) => handleIngredientChange(index, e)}
                            required
                        />
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveIngredient(index)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button type="button" onClick={handleAddIngredient}>
                    Add Ingredient
                </button>
            </div>
            <div className="form-group">
                <label>Steps:</label>
                {steps.map((step, index) => (
                    <div key={index} className="step">
                        <textarea
                            placeholder={`Step ${index + 1}`}
                            value={step}
                            onChange={(e) => handleStepChange(index, e)}
                            required
                        />
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveStep(index)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button type="button" onClick={handleAddStep}>
                    Add Step
                </button>
            </div>
            <button type="submit" className="btn btn-primary">
                Add Recipe
            </button>
        </form>
    );
};

export default RecipeAdd;
