// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Edit Receipt Styling */
.edit-receipt {
    max-width: 90%;
    margin: 30px auto;
    padding: 10px;
}

.error {
    color: #dc3545;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
}

table {
    border-collapse: collapse;
    margin-bottom: 10px;
}

table th,
table td {
    border: 1px solid darkgrey;
    padding: 10px;
    text-align: center;
}

table th {
    background-color: darkgrey;
}
`, "",{"version":3,"sources":["webpack://./src/components/Receipt/Edit.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,cAAc;IACd,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;;IAEI,0BAA0B;IAC1B,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* Edit Receipt Styling */\n.edit-receipt {\n    max-width: 90%;\n    margin: 30px auto;\n    padding: 10px;\n}\n\n.error {\n    color: #dc3545;\n    text-align: center;\n    margin-bottom: 20px;\n    font-size: 18px;\n}\n\ntable {\n    border-collapse: collapse;\n    margin-bottom: 10px;\n}\n\ntable th,\ntable td {\n    border: 1px solid darkgrey;\n    padding: 10px;\n    text-align: center;\n}\n\ntable th {\n    background-color: darkgrey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
