import React, { useState, useEffect } from 'react';
import axiosInstance from 'axios';


// TODO update
const UsersManage = () => {
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get(`/users`);
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users: ", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !email) {
            setError('Name and Email are required');
            return;
        }

        try {
            const response = await axiosInstance.post(`/user`, { name, email });
            setUsers([...users, response.data]);
            setName('');
            setEmail('');
            setError('');
        } catch (error) {
            console.error("Error creating user: ", error);
            setError('Error creating user');
        }
    };

    return (
        <div>
            <h1>User Management</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button type="submit">Submit</button>
            </form>

            <h2>Users List</h2>
            <ul>
                {users.map((user, index) => (
                    <li key={index}>
                        {user.name} ({user.email})
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UsersManage;
