import React, { useState } from "react";
import axiosInstance from '../../axiosInstance';
import "./AddFromUrl.css";


// TODO do we want servings size to be editable here? its already present in the backend
// TODO add loading screen
const RecipeAddFromUrl = ({ userId, addRecipe, addMessage }) => {
    const [url, setUrl] = useState("");
    const [recipe, setRecipe] = useState(null);
    const [error, setError] = useState("");

    const handleUrlSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post(`/recipes/${userId}/fetch`, { url });
            if (response.data.recipe) {
                setRecipe({ ...response.data.recipe, url }); // Include the URL in the recipe
                setError("");
            } else {
                setUrl("");
                addMessage(response.data.message);
            };
        } catch (error) {
            setError("Error fetching recipe information.");
            console.error(error);
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const errors = [];

        if (!recipe.name) errors.push("Recipe name is required.");
        if (recipe.ingredients.some((ingredient) => !ingredient.name || !ingredient.quantity)) {
            errors.push("Each ingredient must have a name and a valid quantity.");
        }

        if (errors.length > 0) {
            alert(errors.join("\n"));
            return;
        }

        try {
            const newRecipe = { ...recipe, user_id: userId };
            const response = await axiosInstance.post(`/recipes/${userId}/add`, newRecipe);
            addRecipe(response.data.recipe);
            setRecipe(null);
            setUrl("");
        } catch (error) {
            console.error("Error adding recipe:", error);
            setError("Failed to add recipe.");
        }
    };

    const handleChange = (e, index = null, type = "ingredients") => {
        const { name, value } = e.target;
        if (index !== null) {
            // Update ingredients or steps
            const updatedItems = [...recipe[type]];
            updatedItems[index][name] = value;
            setRecipe({ ...recipe, [type]: updatedItems });
        } else {
            // Update other fields
            setRecipe({ ...recipe, [name]: value });
        }
    };

    const handleAddItem = (type) => {
        setRecipe({
            ...recipe,
            [type]: [...recipe[type], type === "ingredients" ? { name: "", quantity: "", unit: "" } : ""],
        });
    };

    const handleRemoveItem = (type, index) => {
        setRecipe({
            ...recipe,
            [type]: recipe[type].filter((_, i) => i !== index),
        });
    };

    if (recipe) {
        return (
            <form className="recipe-card" onSubmit={handleEditSubmit}>
                <h2>Edit Recipe</h2>
                <div className="form-group">
                    <h4>Title:</h4>
                    <input
                        type="text"
                        name="name"
                        value={recipe.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <h4>Description:</h4>
                    <textarea
                        name="description"
                        value={recipe.description}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group3">
                    <h4>Ingredients:</h4>
                    {recipe.ingredients.map((ingredient, index) => (
                        <div key={index} className="ingredient">
                            <input
                                type="number"
                                name="quantity"
                                placeholder="Quantity"
                                value={ingredient.quantity}
                                onChange={(e) => handleChange(e, index, "ingredients")}
                                required
                            />
                            <input
                                type="text"
                                name="unit"
                                placeholder="Unit"
                                value={ingredient.unit}
                                onChange={(e) => handleChange(e, index, "ingredients")}
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Ingredient"
                                value={ingredient.name}
                                onChange={(e) => handleChange(e, index, "ingredients")}
                                required
                            />
                            <div className="action-buttons">
                                <button
                                    onClick={() => handleRemoveItem("ingredients", index)}
                                    className="btn btn-danger"
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="action-buttons">
                        <button className="btn" onClick={() => handleAddItem("ingredients")}>
                            Add Ingredient
                        </button>
                    </div>
                </div>
                <h4>Steps:</h4>
                {recipe.steps.map((step, index) => (
                    <div key={index} className="form-group2">
                        <textarea
                            placeholder={`Step ${index + 1}`}
                            value={step}
                            onChange={(e) => handleChange(e, index, "steps")}
                            required
                        />
                        <div className="action-buttons">
                            <button
                                onClick={() => handleRemoveItem("steps", index)}
                                className="btn btn-danger"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                    ))}
                    <div className="action-buttons">
                        <button className="btn" onClick={() => handleAddItem("steps")}>
                            Add Step
                        </button>
                    </div>
                <div className="action-buttons">
                    <button className="btn" type="submit">Submit Recipe</button>
                    <button className="btn btn-danger" onClick={() => setRecipe(null)}>
                        Cancel
                    </button>
                </div>
            </form>
        );
    }

    return (
        <form className="upload-component" onSubmit={handleUrlSubmit}>
            <h2>Recipe URL</h2>
            <input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="https://example.com"
                required
            />
            {error && <p className="error">{error}</p>}
            <div className="action-buttons">
                <button type="submit" className="btn">Fetch Recipe</button>
            </div>
        </form>
    );
};

export default RecipeAddFromUrl;
