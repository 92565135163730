import React, { useEffect, useState } from "react";
import axiosInstance from '../../axiosInstance';
import { useParams, useNavigate } from "react-router-dom";
import "./Edit.css";


const PantryEdit = ({ fetchPantryItems }) => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axiosInstance.get(`/pantry/${userId}/items`);
                setItems(response.data.items);
            } catch (err) {
                console.error("Error fetching pantry items:", err);
                setError("Failed to fetch pantry items.");
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [userId]);

    const handleInputChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const handleDelete = async (itemId, index) => {
        if (itemId) {
            try {
                await axiosInstance.delete(`/pantry/${itemId}`);
                setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
                alert("Item deleted successfully!");
            } catch (err) {
                console.error("Error deleting pantry item:", err);
                alert("Failed to delete pantry item.");
            }
        } else {
            setItems((prevItems) => prevItems.filter((_, i) => i !== index));
        }
    };

    const handleSave = async () => {
        const errors = [];
        items.forEach((item, index) => {
            if (!item.name) errors.push(`Item name is required at row ${index + 1}`);
            if (!item.quantity || isNaN(item.quantity) || item.quantity <= 0) {
                errors.push(`Valid quantity is required at row ${index + 1}`);
            }
        });

        if (errors.length > 0) {
            alert(errors.join("\n"));
            return;
        }

        try {
            const savePromises = items.map(async (item) => {
                if (item.id) {
                    await axiosInstance.put(`/pantry/${userId}`, item, {
                        headers: { "Content-Type": "application/json" },
                    });
                } else {
                    const payload = {
                        name: item.name,
                        quantity: parseFloat(item.quantity),
                        unit: item.unit,
                        expiration_date: item.expiration_date || null,
                        date_bought: item.date_bought,
                    };

                    await axiosInstance.post(`/pantry/${userId}/add`, payload, {
                        headers: { "Content-Type": "application/json" },
                    });
                }
            });

            await Promise.all(savePromises);

            alert("Pantry updated successfully!");
            fetchPantryItems();
            navigate(`/user/${userId}/pantry`);
        } catch (err) {
            console.error("Error updating pantry items:", err);
            alert("Failed to update pantry items.");
        }
    };

    const handleAddRow = () => {
        setItems([
            ...items,
            {
                id: null,
                name: "",
                quantity: null,
                unit: "",
                expiration_date: "",
                date_bought: new Date().toISOString().split("T")[0],
                suppressWarningUntil: null,
            },
        ]);
    };

    const handleDismissMessage = (index) => {
        const updatedItems = [...items];
        updatedItems[index].suppressWarningUntil = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // 7 days from now
        setItems(updatedItems);
    };

    const calculateWeeksSinceBought = (dateBought) => {
        const today = new Date();
        const boughtDate = new Date(dateBought);
        const weeks = Math.floor((today - boughtDate) / (7 * 24 * 60 * 60 * 1000));
        return weeks;
    };

    if (loading) {
        return <p>Loading pantry items...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="page-base">
            <h1>Edit Pantry Items</h1>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Expiration Date</th>
                    <th>Date Bought</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    const isExpired =
                        item.date_bought && calculateWeeksSinceBought(item.date_bought) >= 4;
                    const isWarningSuppressed =
                        item.suppressWarningUntil && item.suppressWarningUntil > new Date().getTime();

                    return (
                        <tr key={index} className={isExpired && !isWarningSuppressed ? "expired-item" : ""}>
                            <td>
                                <input
                                    type="text"
                                    value={item.name}
                                    onChange={(e) => handleInputChange(index, "name", e.target.value)}
                                    required
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => handleInputChange(index, "quantity", parseFloat(e.target.value))}
                                    required
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={item.unit || ""}
                                    onChange={(e) => handleInputChange(index, "unit", e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="date"
                                    value={item.expiration_date || ""}
                                    onChange={(e) => handleInputChange(index, "expiration_date", e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="date"
                                    value={item.date_bought || new Date().toISOString().split("T")[0]}
                                    onChange={(e) => handleInputChange(index, "date_bought", e.target.value)}
                                />
                            </td>
                            <td>
                                {isExpired && !isWarningSuppressed && (
                                    <p className="warning">
                                        This item has been in your pantry for {calculateWeeksSinceBought(item.date_bought)} weeks.
                                        <div className="action-buttons">
                                            <button onClick={() => handleDismissMessage(index)} className="btn" >
                                                Dismiss
                                            </button>
                                        </div>
                                    </p>
                                )}
                                <div className="action-buttons">
                                    <button
                                        onClick={() => handleDelete(item.id, index)}
                                        className="btn btn-danger"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="action-buttons">
                <button onClick={handleAddRow} className="btn">
                    Add Item
                </button>
                <button onClick={handleSave} className="btn">
                    Save Changes
                </button>
            </div>
        </div>
    );
};

export default PantryEdit;
