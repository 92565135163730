import React, { useState, useEffect } from "react";
import axiosInstance from '../../axiosInstance';
import "./ListLatest.css";


const GroceryListLatest = ({ userId }) => {
    const [latestGroceryList, setLatestGroceryList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchLatestGroceryList = async () => {
            try {
                const response = await axiosInstance.get(`/grocery-list/${userId}/latest`);
                setLatestGroceryList(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching grocery lists:", err);
                setError("Failed to load grocery lists.");
                setLoading(false);
            }
        };

        if (userId) {
            fetchLatestGroceryList();
        }
    }, [userId]);

    if (loading) {
        return <p>Loading the latest grocery list...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    if (!latestGroceryList || !latestGroceryList.items || latestGroceryList.items.length === 0) {
        return (
            <div className="saved-grocery-list">
                <h2>Latest Grocery List</h2>
                <p>No saved grocery lists found.</p>
            </div>
        );
    }

    return (
        <div className="saved-list">
            <h2>Latest Grocery List</h2>
            <h3>List from {new Date(latestGroceryList.date).toLocaleDateString()}</h3>
            <ul>
                {latestGroceryList.items.map((item, idx) => (
                    <li key={idx}>
                        {item.name} - {item.quantity} {item.unit} (${item.price || "N/A"})
                    </li>
                ))}
            </ul>
            <h3>Total Estimate: ${latestGroceryList.total.toFixed(2)}</h3>
        </div>
    );
};

export default GroceryListLatest;