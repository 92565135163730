// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Recipe List Styling */
.recipe-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 10px;
}

.recipe-list .no-recipes {
    width: 100%;
    text-align: center;
    color: #555;
    font-size: 20px;
    font-weight: 600;
}

.recipe-card {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(30% - 20px);
    background-color: darkgrey;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid darkgrey;
}

.recipe-card h3 {
    font-size: 22px;
    color: #333;
    margin-bottom: 15px;
}

.recipe-card p {
    font-size: 16px;
    color: #666;
}


.recipe-edit-form {

}`, "",{"version":3,"sources":["webpack://./src/components/Recipe/List.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B;IAC1B,0BAA0B;IAC1B,mBAAmB;IACnB,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;;AAGA;;AAEA","sourcesContent":["/* Recipe List Styling */\n.recipe-list {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n    justify-content: center;\n    padding: 10px;\n}\n\n.recipe-list .no-recipes {\n    width: 100%;\n    text-align: center;\n    color: #555;\n    font-size: 20px;\n    font-weight: 600;\n}\n\n.recipe-card {\n    flex: 1 1 calc(30% - 20px);\n    background-color: darkgrey;\n    border-radius: 10px;\n    padding: 20px;\n    border: 1px solid darkgrey;\n}\n\n.recipe-card h3 {\n    font-size: 22px;\n    color: #333;\n    margin-bottom: 15px;\n}\n\n.recipe-card p {\n    font-size: 16px;\n    color: #666;\n}\n\n\n.recipe-edit-form {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
