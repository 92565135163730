// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Weekly Meal Planner Styling */
.weekly-meal-planner {
    /*width: 100%;*/
    border-radius: 12px;
}

.planner-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    grid-template-rows: auto 1fr;
}

.recipe-list {
    grid-column: span 7;
    display: flex;
    justify-content: space-between;
}

.recipe-droppable {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.meal-item {
    background-color: #e0f7fa;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #e0f7fa;
    text-align: center;
    flex: 1 1;
    color: black;
}

.day-column {
    padding: 15px;
    border-radius: 12px;
    border: 1px solid darkgrey;
    text-align: center;
}

.meal-section {
    background-color: darkgrey;
    border: 1px solid darkgrey;
    border-radius: 12px;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/WeeklyMealPlanner/Manage.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,SAAO;IACP,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":["/* Weekly Meal Planner Styling */\n.weekly-meal-planner {\n    /*width: 100%;*/\n    border-radius: 12px;\n}\n\n.planner-grid {\n    display: grid;\n    grid-template-columns: repeat(7, 1fr);\n    gap: 10px;\n    grid-template-rows: auto 1fr;\n}\n\n.recipe-list {\n    grid-column: span 7;\n    display: flex;\n    justify-content: space-between;\n}\n\n.recipe-droppable {\n    display: flex;\n    align-content: center;\n    justify-content: space-between;\n}\n\n.meal-item {\n    background-color: #e0f7fa;\n    padding: 15px;\n    border-radius: 12px;\n    border: 1px solid #e0f7fa;\n    text-align: center;\n    flex: 1;\n    color: black;\n}\n\n.day-column {\n    padding: 15px;\n    border-radius: 12px;\n    border: 1px solid darkgrey;\n    text-align: center;\n}\n\n.meal-section {\n    background-color: darkgrey;\n    border: 1px solid darkgrey;\n    border-radius: 12px;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
