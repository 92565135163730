import React, { useState, useEffect } from "react";
import axiosInstance from '../../axiosInstance';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Manage.css";


const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const mealTypes = ["Breakfast", "Lunch", "Dinner", "Snacks"];

const initialMeals = daysOfWeek.reduce((acc, day) => {
    acc[day] = mealTypes.reduce((meals, meal) => {
        meals[meal] = [];
        return meals;
    }, {});
    return acc;
}, {});

const WeeklyMealPlannerManage = ({ userId }) => {
    const [meals, setMeals] = useState(initialMeals);
    const [recipes, setRecipes] = useState([]);
    const [shoppingList, setShoppingList] = useState([]);
    const [loading, setLoading] = useState(true);

    const saveMealPlan = async () => {
        try {
            await axiosInstance.post(`/meal-planner/${userId}`, { meals },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                }
            );
            alert("Meal plan saved successfully");
        } catch (error) {
            console.error("Error saving meal plan:", error);
            alert("Error saving meal plan");
        }
    };

    // TODO make this a component of the grocery section
    const generateGroceryList = () => {
        const ingredientsMap = {};

        // Iterate through all meals and gather ingredients
        Object.values(meals).forEach((dayMeals) => {
            Object.values(dayMeals).forEach((mealItems) => {
                mealItems.forEach((recipe) => {
                    recipe.ingredients.forEach((ingredient) => {
                        const key = ingredient.name.toLowerCase(); // Normalize the name for grouping
                        if (ingredientsMap[key]) {
                            // If the ingredient already exists, add the quantities
                            ingredientsMap[key].quantity += parseFloat(ingredient.quantity);
                        } else {
                            // Otherwise, initialize the ingredient
                            ingredientsMap[key] = {
                                name: ingredient.name,
                                quantity: parseFloat(ingredient.quantity),
                                unit: ingredient.unit || "",
                            };
                        }
                    });
                });
            });
        });

        // Convert ingredientsMap to an array of ingredients
        const groceryList = Object.values(ingredientsMap);

        // Set the grocery list to state
        setShoppingList(groceryList);
    };

    const saveGroceryList = async () => {
        try {
            await axiosInstance.post(`/grocery-list/${userId}/save`, {
                items: shoppingList,
                date: new Date().toISOString(), // Include the current date
            });
            alert("Grocery list saved successfully!");
        } catch (error) {
            console.error("Error saving grocery list:", error);
            alert("Failed to save grocery list.");
        }
    };


    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        const sourceDroppableId = source.droppableId.split("-");
        const destDroppableId = destination.droppableId.split("-");

        const sourceType = sourceDroppableId[0];
        // TODO figure out
        // const destType = destDroppableId[0];

        if (sourceType === "recipeList") {
            // Create a copy of the dragged recipe
            const destDay = destDroppableId[1];
            const destMeal = destDroppableId[2];
            const destItems = Array.from(meals[destDay][destMeal]);

            const recipeToCopy = { ...recipes[source.index], uniqueId: Date.now() }; // Add unique ID for duplicates
            destItems.splice(destination.index, 0, recipeToCopy);

            setMeals((prevMeals) => ({
                ...prevMeals,
                [destDay]: {
                    ...prevMeals[destDay],
                    [destMeal]: destItems,
                },
            }));
        } else {
            // Moving recipes within the planner
            const sourceDay = sourceDroppableId[1];
            const sourceMeal = sourceDroppableId[2];
            const destDay = destDroppableId[1];
            const destMeal = destDroppableId[2];

            const sourceItems = Array.from(meals[sourceDay][sourceMeal]);
            const [movedItem] = sourceItems.splice(source.index, 1);
            const destItems = Array.from(meals[destDay][destMeal]);

            destItems.splice(destination.index, 0, movedItem);

            setMeals((prevMeals) => ({
                ...prevMeals,
                [sourceDay]: {
                    ...prevMeals[sourceDay],
                    [sourceMeal]: sourceItems,
                },
                [destDay]: {
                    ...prevMeals[destDay],
                    [destMeal]: destItems,
                },
            }));
        }
    };


    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                const response = await axiosInstance.get(`/recipes/${userId}`);
                const recipes = response.data.recipes || [];
                const selectedRecipes = recipes.filter((recipe) => recipe.selected);
                setRecipes(selectedRecipes);
            } catch (error) {
                console.error("Error fetching recipes:", error);
            }
        };

        const fetchMealPlan = async () => {
            try {
                const response = await axiosInstance.get(`/meal-planner/${userId}`);
                setMeals(Object.keys(response.data.meals || {}).length === 0 ? initialMeals : response.data.meals);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching meal plan:", error);
                setLoading(false);
            }
        };

        fetchRecipes();
        fetchMealPlan();
    }, [userId]);

    if (loading) return <p>Loading...</p>;

    return (
        <div className="weekly-meal-planner">
            <h1>Weekly Meal Planner</h1>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="planner-grid">
                    {/* Recipe list row */}
                    <div className="recipe-list">
                        <h2>Recipes:</h2>
                        <Droppable droppableId="recipeList" direction="horizontal">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="recipe-droppable">
                                    {recipes.map((recipe, index) => (
                                        <Draggable key={recipe._id} draggableId={`recipe-${recipe._id}`} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="meal-item">
                                                    {recipe.name}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    {/* Days of the week */}
                    {daysOfWeek.map((day) => (
                        <div key={day} className="day-column">
                            <h3>{day}</h3>
                            {mealTypes.map((meal) => (
                                <Droppable droppableId={`day-${day}-${meal}`} key={`${day}-${meal}`}>
                                    {(provided) => (
                                        <div className="meal-section" ref={provided.innerRef} {...provided.droppableProps}>
                                            <h3>{meal}</h3>
                                            {meals[day][meal].map((recipe, index) => (
                                                <Draggable key={recipe._id} draggableId={`meal-${recipe._id}`} index={index}>
                                                    {(provided) => (
                                                        <div className="meal-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            {recipe.name}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            ))}
                        </div>
                    ))}
                </div>
            </DragDropContext>
            <div className="action-buttons">
                <button onClick={saveMealPlan} className="btn btn-primary">
                    Save Meal Plan
                </button>
            </div>
            <div className="page-base">
                <div className="action-buttons">
                    <button onClick={generateGroceryList} className="btn btn-primary">
                        Generate Grocery List
                    </button>
                </div>
                {shoppingList.length > 0 && (
                    <div className="shopping-list">
                        <h2>Grocery List</h2>
                        <ul>
                            {shoppingList.map((item, index) => (
                                <li key={index}>
                                    {item.quantity} {item.unit} {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="action-buttons">
                    <button onClick={saveGroceryList} className="btn btn-secondary">
                        Save Grocery List
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WeeklyMealPlannerManage;
