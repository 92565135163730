import axios from "axios";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true, // Allows cookies (refresh_token) to be sent
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401 && !error.config.__isRetry) {
            try {
                // Prevent infinite loops
                error.config.__isRetry = true;

                // Attempt to refresh the access token
                const refreshResponse = await axios.post(`${API_BASE_URL}/auth/refresh`, {}, { withCredentials: true });

                const newToken = refreshResponse.data.access_token;
                const tokenType = refreshResponse.data.token_type || "Bearer";

                // TODO send authToken through all apis to get user info
                localStorage.setItem("authToken", newToken);
                // Update headers and retry the original request
                axiosInstance.defaults.headers.common["Authorization"] = `${tokenType} ${newToken}`;
                error.config.headers["Authorization"] = `${tokenType} ${newToken}`;
                return axiosInstance(error.config);
            } catch (refreshError) {
                console.error("Failed to refresh token:", refreshError);
                localStorage.removeItem("authToken");
                delete axiosInstance.defaults.headers.common["Authorization"];
                window.location.href = "/auth"; // Redirect to login page
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
