// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Grocery List Edit Styling */
.add-new-item {
    display: flex;
    align-items: center;
    gap: 15px;
}

.add-new-item input {
    flex: 1 1;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Grocery/Edit.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":["/* Grocery List Edit Styling */\n.add-new-item {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n}\n\n.add-new-item input {\n    flex: 1;\n    padding: 10px;\n    border: 1px solid #ccc;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
