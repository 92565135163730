import React, { useState, useEffect } from "react";
import axiosInstance from '../../axiosInstance';
import "./Manage.css";


const CollabManage = ({ userId }) => {
    const [collaborators, setCollaborators] = useState([]);
    const [newCollaboratorId, setNewCollaboratorId] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [pendingInvite, setPendingInvite] = useState(null);

    // TODO the parameters here cause the api to go wild when invite pending
    useEffect(() => {
        const fetchCollaborators = async () => {
            try {
                const response = await axiosInstance.get(`/shared-pantries/${userId}/collaborators`);
                setCollaborators(response.data.collaborators);
            } catch (error) {
                console.error("Error fetching collaborators:", error);
                setError("Failed to load collaborators.");
            }
        };

        const fetchPendingInvite = async () => {
            try {
                const response = await axiosInstance.get(`/shared-pantries/${userId}/pending-invite`);
                if (response.data && response.data.invitation) {
                    setPendingInvite(response.data.invitation);
                }
            } catch (error) {
                console.error("Error checking for pending invite:", error);
                setError("Failed to check pending invitations.");
            }
        };

        fetchCollaborators();
        fetchPendingInvite();
    }, [userId, newCollaboratorId]);

    const handleAddCollaborator = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        if (!newCollaboratorId.trim()) {
            setError("User ID is required.");
            return;
        }

        try {
            const response = await axiosInstance.post(`/shared-pantries/${userId}/invite`,
                {
                    collaborator_id: newCollaboratorId.trim(),
                    authToken: localStorage.getItem("authToken"),
                } ,
                {
                    headers: { "Content-Type": "application/json", },
                });
            setNewCollaboratorId("");
            setSuccess(response.data.message)
        } catch (error) {
            console.error("Error adding collaborator:", error);
            setError("Failed to add collaborator. Ensure the user exists and is not already in the pantry.");
        }
    };

    const handleRemoveCollaborator = async (collaboratorId) => {
        setError("");
        setSuccess("");

        try {
            await axiosInstance.delete(`/shared-pantries/${userId}/remove-collaborator/${collaboratorId}`);
            setCollaborators(collaborators.filter((collab) => collab.user_id !== collaboratorId));
            setSuccess("Collaborator removed successfully!");
        } catch (error) {
            console.error("Error removing collaborator:", error);
            setError("Failed to remove collaborator.");
        }
    };

    const acceptInvitation = async () => {
        try {
            await axiosInstance.post(`/shared-pantries/${userId}/accept-invitation`, {
                pantry_id: pendingInvite.pantry_id,
            });
            setSuccess("Invitation accepted successfully!");
            setPendingInvite(null); // Clear the pending invite after accepting
        } catch (error) {
            console.error("Error accepting invitation:", error);
            setError("Failed to accept the invitation.");
        }
    };

    return (
        <div className="page-base">
            <h1>Manage Collaborators</h1>

            {pendingInvite && (
                <div>
                    <h2>You have a pending invitation to a shared pantry!</h2>
                    <p>Pantry invite from {pendingInvite.user_id}</p>
                    <div className="action-buttons">
                        <button onClick={acceptInvitation} className="btn">
                            Accept Invitation
                        </button>
                    </div>
                </div>
            )}

            <div>
                <form onSubmit={handleAddCollaborator} className="add-new-item">
                    <input
                        type="text"
                        placeholder="Enter User ID"
                        value={newCollaboratorId}
                        onChange={(e) => setNewCollaboratorId(e.target.value)}
                        required
                    />
                    <div className="action-buttons">
                        <button type="submit" className="btn" >Add Collaborator</button>
                    </div>
                </form>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </div>

            <div className="list-grocery">
                <h2>Current Collaborators</h2>
                <div>
                {collaborators.length > 0 ? (
                    <ul >
                        {collaborators.map((collaborator) => (
                            <li key={collaborator.user_id}>
                                <div>
                                    {collaborator.owner ? "Owner:" : ""} {collaborator.user_id} ({collaborator.status})
                                </div>
                                <div className="action-buttons">
                                    <button
                                        onClick={() => handleRemoveCollaborator(collaborator.user_id)}
                                        className="btn btn-danger"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No collaborators yet.</p>
                )}
                </div>
            </div>
        </div>
    );
};

export default CollabManage;
