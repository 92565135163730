// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pantry Manage Styling */

/* Responsive Styling */
@media (max-width: 768px) {
    .pantry-items-list {
        gap: 15px;
    }

    .pantry-item {
        width: 90%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Pantry/Manage.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B,uBAAuB;AACvB;IACI;QACI,SAAS;IACb;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":["/* Pantry Manage Styling */\n\n/* Responsive Styling */\n@media (max-width: 768px) {\n    .pantry-items-list {\n        gap: 15px;\n    }\n\n    .pantry-item {\n        width: 90%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
