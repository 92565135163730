// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer Styling */
.app-footer {
    padding: 5px;
    position: relative;
    margin-top: auto;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.links a {
    margin: 0 15px;
    color: #BB86FC;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
}

.links a:hover {
    color: #9B67E0;
    text-decoration: underline;
}

.social-media a {
    margin: 0 15px;
    color: #555;
    text-decoration: none;
}

.social-media a:hover {
    text-decoration: underline;
}

.copyright {
    font-size: 14px;
    color: #666;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":["/* Footer Styling */\n.app-footer {\n    padding: 5px;\n    position: relative;\n    margin-top: auto;\n}\n\n.footer-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.links a {\n    margin: 0 15px;\n    color: #BB86FC;\n    text-decoration: none;\n    font-size: 16px;\n    font-weight: 500;\n    transition: color 0.3s ease-in-out;\n}\n\n.links a:hover {\n    color: #9B67E0;\n    text-decoration: underline;\n}\n\n.social-media a {\n    margin: 0 15px;\n    color: #555;\n    text-decoration: none;\n}\n\n.social-media a:hover {\n    text-decoration: underline;\n}\n\n.copyright {\n    font-size: 14px;\n    color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
