import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../../axiosInstance';
import "./Manage.css";


const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const mealTypes = ["Breakfast", "Lunch", "Dinner", "Snacks"];

const WeeklyMealPlannerDisplay = ({ userId }) => {
    const [meals, setMeals] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMealPlan = async () => {
            try {
                const response = await axiosInstance.get(`/meal-planner/${userId}`);
                setMeals(response.data.meals || {});
                setLoading(false);
            } catch (error) {
                console.error("Error fetching meal plan:", error);
                setLoading(false);
            }
        };

        fetchMealPlan();
    }, [userId]);

    if (loading) return <p>Loading meal plan...</p>;

    return (
        <div className="weekly-meal-plan-display">
            <h1>Weekly Meal Plan</h1>
            <div className="planner-grid">
                {daysOfWeek.map((day) => (
                    <div key={day} className="day-column">
                        <h2>{day}</h2>
                        {mealTypes.map((mealType) => (
                            <div key={`${day}-${mealType}`} className="meal-section">
                                <h3>{mealType}</h3>
                                <ul>
                                    {meals[day]?.[mealType]?.map((recipe, index) => (
                                        <li key={index}>{recipe.name}</li>
                                    )) || <li>No recipes planned</li>}
                                </ul>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <button
                className="btn btn-primary"
                onClick={() => navigate(`/user/${userId}/meal-planner`)}
            >
                Edit Meal Plan
            </button>
        </div>
    );
};

export default WeeklyMealPlannerDisplay;
