// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Edit Pantry Styling */

/* Responsive Styling */
@media (max-width: 768px) {
    .edit-pantry h1 {
        font-size: 24px;
    }

}
`, "",{"version":3,"sources":["webpack://./src/components/Pantry/Edit.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB,uBAAuB;AACvB;IACI;QACI,eAAe;IACnB;;AAEJ","sourcesContent":["/* Edit Pantry Styling */\n\n/* Responsive Styling */\n@media (max-width: 768px) {\n    .edit-pantry h1 {\n        font-size: 24px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
