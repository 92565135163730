import React, { useState, useEffect } from "react";
import axiosInstance from '../../axiosInstance';


const RecipeWithAllIngredients = ({ userId }) => {
    const [recipes, setRecipes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                const response = await axiosInstance.get(`/recipes/${userId}/recipes-with-all-ingredients`);
                setRecipes(response.data.recipes || []);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching recipes:", err);
                setError("Failed to load recipes.");
                setLoading(false);
            }
        };

        if (userId) {
            fetchRecipes();
        }
    }, [userId]);

    if (loading) return <p>Loading recipes...</p>;
    if (error) return <p className="error">{error}</p>;
    if (recipes.length === 0) return <p>No recipes with all ingredients found.</p>;

    return (
        <div className="saved-list">
            <h2>Recipes You Can Make Now</h2>
            <ul>
                {recipes.map((recipe) => (
                    <li key={recipe.id}>
                        <strong>{recipe.name}</strong>
                        {recipe.url && (
                            <a href={recipe.url} target="_blank" rel="noopener noreferrer" className="recipe-link">
                                View Full Recipe
                            </a>
                        )}
                        <p>{recipe.description || "No description available."}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecipeWithAllIngredients;
