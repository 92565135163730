import React from 'react';
// import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div>
            <h1>What You Can Do with Receipt2Recipe</h1>
            <ul>
                <li>Upload your shopping receipts to generate personalized meal plans.</li>
                <li>Receive AI-powered recipe suggestions based on ingredients from your receipts.</li>
                <li>Filter meals based on dietary preferences and nutrition goals.</li>
                <li>Save, edit, and organize your favorite recipes.</li>
                <li>Share meal plans with friends and family.</li>
            </ul>

            <h1>Upcoming Features</h1>
            <ul>
                <li>Direct integration with grocery stores for automatic receipt imports.</li>
                <li>AI-powered meal customization based on taste preferences.</li>
                <li>Smart pantry tracking to suggest meals based on available ingredients.</li>
                <li>Mobile app launch for seamless on-the-go meal planning.</li>
                <li>Community-driven recipe sharing and user ratings.</li>
            </ul>
        </div>
    );
};

export default Home;
