import React from "react";
import { useNavigate, Link } from "react-router-dom";
import "./NavBar.css";

const NavBar = ({ auth, setAuth, userId, onLogout }) => {
    const navigate = useNavigate();

    const handleLogout = (e) => {
        onLogout();
        navigate("/");
    };

    return (
        <nav className="navbar">
            <ul className="navbar-list">
                {auth ? (
                    <>
                        <li className="navbar-item">
                            <Link to={`/user/${userId}`}>{userId}</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to={`/user/${userId}/pantry`}>Pantry</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to={`/user/${userId}/recipes`}>Recipes</Link>
                        </li>

                        <li className="navbar-item">
                            <Link to={`/user/${userId}/grocery-list`}>Grocery List</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to={`/user/${userId}/meal-planner`}>Meal Planner</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to={`/user/${userId}/collaboration`}>Collaborations</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/auth" onClick={handleLogout} className="btn">
                                Logout
                            </Link>
                        </li>
                    </>
                ) : (
                    <>
                        <li className="navbar-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/auth">Login</Link>
                        </li>
                        <li>
                            <Link to="/register">Register</Link>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

export default NavBar;
