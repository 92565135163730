import React, { useEffect, useState } from "react";
import "./List.css";

const RecipeList = ({
        recipes,
        pantryItems,
        onSelectRecipe,
        onEditRecipe,
        onUpdateRecipe,
        onDeleteRecipe,
        onToggleFavorite,
        editingRecipe,
        onUpdateInteraction,
    }) => {
    const [recipeAvailability, setRecipeAvailability] = useState({});

    const handleIngredientChange = (index, field, value) => {
        const updatedIngredients = [...editingRecipe.ingredients];
        updatedIngredients[index][field] = value;
        onEditRecipe({ ...editingRecipe, ingredients: updatedIngredients });
    };

    const handleServingsChange = (recipeId, newServings) => {
        const parsedServings = parseInt(newServings, 10) || 1;
        onUpdateInteraction(recipeId, { user_servings: parsedServings });
    };

    const handleAddIngredient = () => {
        const updatedIngredients = [...editingRecipe.ingredients, { name: "", quantity: "", unit: "" }];
        onEditRecipe({ ...editingRecipe, ingredients: updatedIngredients });
    };

    const handleRemoveIngredient = (index) => {
        const updatedIngredients = editingRecipe.ingredients.filter((_, i) => i !== index);
        onEditRecipe({ ...editingRecipe, ingredients: updatedIngredients });
    };

    const handleStepChange = (index, value) => {
        const updatedSteps = [...editingRecipe.steps];
        updatedSteps[index] = value;
        onEditRecipe({ ...editingRecipe, steps: updatedSteps });
    };

    const handleAddStep = () => {
        const updatedSteps = [...editingRecipe.steps, ""];
        onEditRecipe({ ...editingRecipe, steps: updatedSteps });
    };

    const handleRemoveStep = (index) => {
        const updatedSteps = editingRecipe.steps.filter((_, i) => i !== index);
        onEditRecipe({ ...editingRecipe, steps: updatedSteps });
    };

    useEffect(() => {
        const computeAvailability = () => {
            const availability = recipes.reduce((acc, recipe) => {
                const ingredientsStatus = recipe.ingredients.map((ingredient) => {
                    const pantryItem = pantryItems.find(
                        (item) => item.name.toLowerCase() === ingredient.name.toLowerCase()
                    );
                    const available = !!pantryItem;
                    const enough =
                        pantryItem &&
                        parseFloat(pantryItem.quantity) >= parseFloat(ingredient.quantity);

                    return { ...ingredient, available, enough };
                });
                acc[recipe.id] = ingredientsStatus;
                return acc;
            }, {});
            setRecipeAvailability(availability);
        };

        computeAvailability();
    }, [recipes, pantryItems]);

    if (recipes.length === 0) {
        return <p className="no-recipes">No recipes available.</p>;
    }

    return (
        <div className="recipe-list">
            {recipes.map((recipe) =>
                editingRecipe && editingRecipe.id === recipe.id ? (
                    <div key={recipe.id} className="recipe-edit-form">
                        {/* Editing Recipe */}
                        <input
                            type="text"
                            value={editingRecipe.name}
                            onChange={(e) => onEditRecipe({ ...editingRecipe, name: e.target.value })}
                            placeholder="Recipe Name"
                        />
                        <textarea
                            value={editingRecipe.description}
                            onChange={(e) => onEditRecipe({ ...editingRecipe, description: e.target.value })}
                            placeholder="Recipe Description"
                        />
                        <h3>Ingredients:</h3>
                        <ul>
                            {editingRecipe.ingredients.map((ingredient, index) => (
                                <li key={index}>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={ingredient.name}
                                        onChange={(e) => handleIngredientChange(index, "name", e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Quantity"
                                        value={ingredient.quantity}
                                        onChange={(e) => handleIngredientChange(index, "quantity", e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Unit"
                                        value={ingredient.unit}
                                        onChange={(e) => handleIngredientChange(index, "unit", e.target.value)}
                                    />
                                    <div className="action-buttons">
                                        <button onClick={() => handleRemoveIngredient(index)} className="btn btn-danger">
                                            Remove
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="action-buttons">
                            <button onClick={handleAddIngredient} className="btn">
                                Add Ingredient
                            </button>
                        </div>
                        <h3>Steps:</h3>
                        <ol>
                            {editingRecipe.steps.map((step, index) => (
                                <li key={index}>
                                    <textarea
                                        value={step}
                                        onChange={(e) => handleStepChange(index, e.target.value)}
                                        placeholder={`Step ${index + 1}`}
                                    />
                                    <div className="action-buttons">
                                        <button onClick={() => handleRemoveStep(index)} className="btn btn-danger">
                                            Remove
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ol>
                        <div className="action-buttons">
                            <button onClick={handleAddStep} className="btn">
                                Add Step
                            </button>
                            <button onClick={() => onUpdateRecipe(editingRecipe)} className="btn">
                                Save
                            </button>
                            <button onClick={() => onEditRecipe(null)} className="btn">
                                Cancel
                            </button>
                        </div>
                    </div>
                ) : (
                    <div key={recipe.id} className="recipe-card">
                        <h3>{recipe.name} {""}
                            <input
                                type="checkbox"
                                checked={recipe.selected}
                                onChange={() => onSelectRecipe(recipe)}
                                className="select-checkbox"
                            />
                        </h3>
                        <p>{recipe.description || "No description available."}</p>

                        <p>
                            <strong>Has All Ingredients:</strong> {recipe.has_all_ingredients ? "✅ Yes" : "❌ No"}
                        </p>

                        <div className="serving-control">
                            <label>Servings:</label>
                            <input
                                type="number"
                                value={recipe.user_servings || recipe.servings}
                                onChange={(e) => handleServingsChange(recipe, e.target.value)}
                                min="1"
                                />
                        </div>
                        <p><strong>Estimated Total:</strong> ${recipe.price?.toFixed(2) || "N/A"}</p>

                        {recipe.url && (
                            <a href={recipe.url} target="_blank" rel="noopener noreferrer" className="recipe-link">
                                View Full Recipe
                            </a>
                        )}
                        <div className="action-buttons">
                            <button
                                onClick={() => onToggleFavorite(recipe)}
                                className={recipe.favorited ? "btn btn-favorite" : "btn btn-not-favorite"}
                            >
                                {recipe.favorited ? "Unfavorite" : "Favorite"}
                            </button>
                            <button onClick={() => onEditRecipe(recipe)} className="btn">
                                Edit
                            </button>
                            <button onClick={() => onDeleteRecipe(recipe.id)} className="btn btn-danger">
                                Delete
                            </button>
                        </div>
                        <h3>Ingredients:</h3>
                        <ul>
                            {recipe.ingredients.map((ingredient, index) => {
                                let scaledQuantity = recipe.servings
                                if (recipe.user_servings) scaledQuantity = (
                                    (ingredient.quantity / recipe.servings) * recipe.user_servings
                                ).toFixed(2);
                                const status =
                                    recipeAvailability[recipe.id]?.[index] || { available: false, enough: false };
                                let color = "darkred";
                                if (status.available && status.enough) color = "darkgreen";
                                else if (status.available && !status.enough) color = "darkorange";

                                return (
                                    <li key={index} style={{ color }}>
                                        {scaledQuantity} {ingredient.unit} {ingredient.name}
                                        {!status.available && " (Missing)"}
                                        {status.available && !status.enough && " (Not enough)"}
                                    </li>
                                );
                            })}
                        </ul>
                        <h3>Instructions:</h3>
                        <ol>
                            {recipe.steps.map((step, index) => (
                                <li key={index}>{step}</li>
                            ))}
                        </ol>
                    </div>
                )
            )}
        </div>
    );
};

export default RecipeList;
