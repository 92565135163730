import React, { useState, useEffect } from "react";
import axiosInstance from '../../axiosInstance';


const RecipeListSelected = ({ userId }) => {
    const [selectedRecipes, setSelectedRecipes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");


    useEffect(() => {
        const fetchSelectedRecipes = async () => {
            try {
                const response = await axiosInstance.get(`/recipes/${userId}`);
                const recipes = response.data.recipes || [];
                const selected = recipes.filter((recipe) => recipe.selected);
                setSelectedRecipes(selected);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching selected recipes:", err);
                setError("Failed to load selected recipes.");
                setLoading(false);
            }
        };

        if (userId) {
            fetchSelectedRecipes();
        }
    }, [userId]);

    if (loading) {
        return <p>Loading selected recipes...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    if (selectedRecipes.length === 0) {
        return (
            <div className="selected-recipes-list">
                <h2>
                    <a href={`/user/${userId}/recipes`}>Selected Recipes</a>
                </h2>
                <p>No selected recipes found.</p>
            </div>
        );
    }

    return (
        <div className="saved-list">
            <h2>Selected Recipes</h2>
            <ul>
                {selectedRecipes.map((recipe) => (
                    <li key={recipe.id}>
                        <strong>{recipe.name}: </strong>
                        {recipe.url && (
                            <a href={recipe.url} target="_blank" rel="noopener noreferrer" className="recipe-link">
                                View Full Recipe
                            </a>
                        )}
                        <p>{recipe.description || "No description available."}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecipeListSelected;
