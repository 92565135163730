import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../../axiosInstance';
import "./Add.css";


const ReceiptAdd = ({ userId }) => {
    const [file, setFile] = useState(null);
    const [photoPreview, setPhotoPreview] = useState(null); // For photo preview
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (photoPreview) {
            URL.revokeObjectURL(photoPreview); // Cleanup previous URL
        }
        setFile(selectedFile);
        setPhotoPreview(URL.createObjectURL(selectedFile)); // Generate preview URL
    };

    const handleClearPhoto = () => {
        if (photoPreview) {
            URL.revokeObjectURL(photoPreview); // Cleanup previous URL
        }
        setFile(null);
        setPhotoPreview(null);
    };

    const handleUpload = async () => {
        if (!file) {
            setError("Please select a file to upload.");
            return;
        }

        setUploading(true);
        setError("");

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axiosInstance.post(`/receipts/${userId}/upload`,  formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );
            if (response.status === 200) {
                if (response.data.message) {
                    // Redirect to pantry with a message
                    navigate(`/user/${userId}/pantry`, {
                        state: { message: response.data.message }
                    });
                } else if (response.data.structured_data) {
                    // Redirect to receipt edit page
                    navigate(`/user/${userId}/edit-receipt`, {
                        state: {
                            receiptData: response.data.structured_data,
                            photo: photoPreview,
                        }
                    });
                } else {
                    setError("Unexpected response from server.");
                }
                handleClearPhoto();
            } else {
                setError("Failed to upload receipt.");
            }
        } catch (err) {
            console.error("Upload error:", err);
            setError("An error occurred during upload.");
        } finally {
            setUploading(false);
        }
    };

    // Cleanup when component unmounts
    useEffect(() => {
        return () => {
            if (photoPreview) {
                URL.revokeObjectURL(photoPreview);
            }
        };
    }, [photoPreview]);

    return (
        <div className="upload-component">
            <h2>Upload Receipt</h2>
            <input type="file" accept="image/*" onChange={handleFileChange}/>
            {photoPreview && (
                <div className="photo-preview">
                    <h4>Photo Preview:</h4>
                    <img src={photoPreview} alt="Receipt Preview"/>
                    <div className="action-buttons">
                        <button onClick={handleClearPhoto} className="btn btn-danger">Clear Photo</button>
                    </div>
                </div>
            )}
            {error && <p className="upload-error">{error}</p>}
            <div className="action-buttons">
                <button onClick={handleUpload} disabled={uploading} className="btn">
                    {uploading ? "Uploading..." : "Upload"}
                </button>
            </div>
        </div>
    );
};

export default ReceiptAdd;
